export const data = {
    en: {
        navLinks: ['philosophy', 'book', 'about', 'contact'],
        titleQuote: 'Soft and harmonising movements to find balance in the breathing cycle.',
        philosophyTitle: 'The philosophy',
        philosophyText: [
            "Passionate about rock and pop music, Asher aims to provide different types of lessons tailored to your specific needs. One can choose to work solely on breathing through MDH breathing coordination.",
            "« The goal of this work is to amplify and harmonize the movement of the diaphragm to restore the respiratory function as a whole. » (breathingcoordination.ch)",
            "One could choose to take singing lessons with a focus on vocal coaching and interpretation. The goal would be to develop a repertoire based on your motivations and favorite genres and to perfect your approach when it comes to the use of your voice in a particular setting. And it is also possible to mix these two, to find a balance that suits your needs. The philosophy is always to teach in a horizontal manner where your needs are met through a gentle and open-minded attitude."
        ],
        aboutTitle: 'About Asher',
        aboutText: [
            "Asher Varadi was born in Geneva and grew up in a multicultural family. Being raised in a family with music but also with parents who were teachers and passionate about healing, fostered his passion for both music and coaching.",
            "Asher studied psychology at the university of Geneva and then went on to study music at the HEMU in Lausanne. It’s at the HEMU that Asher met Robin de Haas, a mentor and teacher who taught him MDH breathing coordination. During his training Asher discovered his passion for the method and decided to share it with others. He also met and trained with Lynn Martin, one of Carl Stough’s students. It’s in these steps that he wishes to continue and transmit the approach of MDH breathing coordination: A method which focuses on teaching or reteaching us how to breathe, as we did when we came out of the womb. At Catalyse (Geneva), he learnt the ‘Sonoga’ pedagogy, which gave him tools on how to teach with awareness and positivity.",
            "Asher is also a practicing musician who plays in various bands such as Etienne Machine, Ash the Ash and Sherkan, with a background in rock, electronica and pop. He teaches singing at Catalyse (Geneva) and in private. He also works as a producer and composer in various settings such as for independent filmmakers and film producers. "
        ],
        quotesNames: ['Livio', 'Léane', 'Lisha'],
        quotesRoles: ['Singer', 'Singer', 'Breathing Coordination'],
        quotesText: [
            "Great pedagogy! The classes are well organised and prepared specifically for each individual. We focus on themes such as vocal support and breathing coordination. Everything is done with precision and clear imagery. The classes take place with a sharing spirit. Between singing, introspection, learning how the body works and discussions, Asher makes us feel comfortable and ready to give our very best.",
            "What I like of the classes with Asher is that he knows how to combine theoretical knowledge on vocal technique with great awareness. He is implicated and understanding of his student whilst getting us to reach our objectives. The relationship with Asher is always simple and without judgment and it is always a pleasurable moment to be working with this talented artist.",
            "Having chronic asthma since childhood, thanks to the work with Asher Varadi, I was able to learn how to listen to my breathing and apprehend my breathing difficulties, especially during the exhale. I really recommend it !"
        ],
        courseTitles: ['MDH Breathing Coordination', 'Singing Classes / Vocal Coaching'],
        coursePrices: ['100.-/hour', '85.-/hour'],
        courseDiscount: 'Student and AVS reduction : 10 %',
        courseEmailSubject: ['mailto:info@ashervaradi.ch?subject=Booking MDH', 'mailto:info@ashervaradi.ch?subject=Booking CV']
    },
    fr: {
        navLinks: ['philosophie', 'cours', 'présentation', 'contact'],
        titleQuote: 'Des mouvements doux pour retrouver de l’équilibre dans votre cycle respiratoire.',
        philosophyTitle: 'La philosophie',
        philosophyText: [
            "Passionné par le rock et les musiques populaires, Asher propose une variété de leçons de chant entièrement adaptées à vos besoins.",
            "Vous avez le choix entre travailler spécifiquement sur la coordination respiratoire MDH, afin d'amplifier et d'harmoniser le mouvement du diaphragme pour restaurer pleinement votre fonction respiratoire.",
            "Une autre option consiste à prendre des cours de chant axés sur le coaching vocal et l'interprétation, afin de développer un répertoire basé sur vos motivations et vos genres musicaux préférés. Vous perfectionnerez ainsi votre approche vocale dans différents contextes.",
            "Enfin, il est également possible de combiner les deux aspects en trouvant l'équilibre qui vous convient.",
            "Quelle que soit votre décision, notre philosophie d'enseignement est toujours axée sur une approche horizontale, visant à répondre en douceur à vos besoins spécifiques."
        ],
        aboutTitle: 'Présentation',
        aboutText: [
            "Asher Varadi est né à Genève dans un environnement multiculturel. Élevé par des parents musiciens mais aussi enseignants et thérapeutes, l’ont poussé à développer une passion pour ces domaines.",
            "Asher a étudié la psychologie à l’université de Genève et ensuite le chant musiques actuelles à la HEMU de Lausanne. C’est lors de son parcours d’étude, qu’il fait la rencontre de Robin de Haas, un professeur et mentor qui lui a enseigné la coordination respiratoire MDH. Lors de sa formation, Asher découvre sa passion pour la méthode et décide de la transmettre à d’autres. Il a aussi pu étudier avec Lynn Martin, une élève de Carl Stough. C’est dans ces pas qu’il souhaiterait continuer et transmettre l’approche de la coordination MDH : Une méthode qui se concentre sur l’enseignement ou le ré-enseignement de la respiration. A Catalyse (Genève), Asher a étudié la pédagogie ‘Sonoga’, ce qui lui a donné des outils pour enseigner avec conscience et bienveillance.",
            "Asher est aussi un musicien professionnel avec un parcours de la scène actuelle et des collaborations avec plusieurs groupes de la région tel qu’Etienne Machine, Ash the Ash ou encore Sherkan. Des groupes évoluant dans des styles tel que le rock, l’electronica ou encore la pop.",
            "Il enseigne le chant à Catalyse (Ge) depuis plusieurs années et il travaille aussi entant que compositeur et producteur de musique pour des réalisateurs de films indépendants."
        ],
        quotesNames: ['Livio', 'Léane', 'Lisha'],
        quotesRoles: ['Chanteur', 'Chanteuse', "Coordination Respiratoire"],
        quotesText: [
            "Super pédagogie! Les cours sont bien organisés et préparé sur mesure en fonction des élèves. On aborde des thèmes comme le soutien et la coordination respiratoire. Le tout de manière claire et bien imagé. Les cours se passent dans un esprit de partage. Entre chant, introspection, apprentissage du corps et discussion, Asher nous fait se sentir à l'aise et donner le meilleur de nous même.",
            "Ce qui me plaît dans les cours avec Asher, c’est qu’il sait combiner un savoir théorique de la technique vocale avec une grande bienveillance. Il est soucieux de comprendre son élève et de l’aider à atteindre ses objectifs. Le contact avec Asher est toujours simple et sans jugement et c’est toujours un bon moment que d’être suivi par cet artiste talentueux.",
            "Asthmatique chronique depuis l’enfance, grâce aux séances avec Asher Varadi j’ai pu apprendre à écouter mon souffle et mieux appréhender mes difficultés respiratoires, notamment à l’expire. Je recommande vivement !"
        ],
        courseTitles: ['MDH Coordination Respiratoire', 'Cours de Chant/ Coaching Vocal'],
        coursePrices: ['100.-/heure', '85.-/heure'],
        courseDiscount: 'Étudiant·e et réduction AVS: 10 %',
        courseEmailSubject: ['mailto:info@ashervaradi.ch?subject=Booking MDH', 'mailto:info@ashervaradi.ch?subject=Booking CV']
    }
}